import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Reveal from 'react-reveal/Reveal';

import SimpleMap from '../components/Map';
import Footer from '../components/Footer';

import { Efect, Efect1, Efect2 } from "../styles/effect.styles";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      const response = await fetch('https://formsubmit.co/info@avihagroup.com', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(formData).toString() // Use formData directly
      });

      if (response.ok) {
        setSubmitSuccess(true);
        setFormData({ name: '', email: '', message: '' }); // Clear form fields
      } else {
        throw new Error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error state or display error message
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Efect />
      <Efect1 />
      <Efect2 />

      <Reveal effect="fadeInUp">
        <section className='container'>
          <div className='row'>
            <div className='col-12'>
              <br />
              <h1>Connect with us to unlock global opportunities and <br />achieve your <span className='color'>career</span> aspirations</h1>
            </div>
          </div>
        </section>
      </Reveal>

      <Reveal effect="fadeInUp">
        <section className='container pt-0'>
          <div className='row'>
            <div className='col-12'>
              <SimpleMap />
            </div>
            <div className='col-md-6'>
              <div className="text-side">
                <h3 className="heading">Talk with our expert team </h3>
                <p>Our dedicated team is committed to providing the support and insights you need to succeed in your chosen field.</p>
                <div className='address'>
                  <div className='heading'>Our Office</div>
                  <div className='list'>
                    <i className="fa fa-map-marker"></i>
                    57/8, Dickman’s Road, Colombo 05, Sri Lanka.
                  </div>
                  <div className='list'>
                    <i className="fa fa-envelope-o"></i>
                    <a href='mailto:info@avihagroup.com' target='_blank' rel='noopener noreferrer'>
                      info@avihagroup.com
                    </a>
                  </div>
                  <div className='list'>
                    <i className="fa fa-phone"></i>
                    (+94) 11 458 8301
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-6'>
              <div className="form-side">
                {submitSuccess ? (
                  <div className="success-message">
                    <p>Thank you for contacting us! We will get back to you soon.</p>
                  </div>
                ) : (
                  <form 
                    className="formcontact" 
                    action="https://formsubmit.co/info@avihagroup.com" 
                    method="POST"
                    onSubmit={handleSubmit}
                  >
                    <input type="hidden" name="_subject" value="New Contact Form Submission!" />
                    <input type="hidden" name="_next" value="https://avihagroup.com/contact/" />
                    <input type="hidden" name="_captcha" value="true" />

                    <label>Name</label>
                    <input 
                      type="text" 
                      name="name" 
                      value={formData.name} 
                      onChange={handleChange} 
                      required 
                    />
                    <label>Email</label>
                    <input 
                      type="email" 
                      name="email" 
                      value={formData.email} 
                      onChange={handleChange} 
                      required 
                    />
                    <label>Message</label>
                    <textarea 
                      name="message" 
                      value={formData.message} 
                      onChange={handleChange} 
                      required 
                    />
                    <button type="submit" id='buttonsent' disabled={submitting}>
                      <span className="shine"></span>
                      <span>{submitting ? 'Sending...' : 'Send'}</span>
                    </button>
                  </form>
                )}
              </div>
            </div>

          </div>
        </section>
      </Reveal>

      <Footer />

    </div>
  );
};

export default withRouter(Contact);
