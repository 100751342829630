import React, { Component } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return (
      <div {...props}></div>
    );
  }
}

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      autoPlay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 5000,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            dots: false
          }
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            dots: false
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className='slick slickproject'>
        <Slider {...settings}>


          <CustomSlide className='itm' index={1}>
            <div className='bg'>
              <img
                src="./img/feature/1.png"
                className="img-fluid"
                alt="Imageteam"
              />

            </div>
            <div className='desc' style={{ marginBottom: '70px' }}>
              <div className='name'>Colombo Bartender & Barista School</div>
              <div className='content'>
                Welcome to Sri Lanka's leading Bartender & Barista School.We pride ourselves on 6+ years of training & mentoring Baristas & Bartenders, and beyond.
              </div>
            </div>
            <div className='icon'>
              <span onClick={() => window.open("https://barbaristaschool.com/", "_blank")}>
                View Site
              </span>
            </div>
          </CustomSlide>


          <CustomSlide className='itm' index={2}>
            <div className='bg'>
              <img
                src="./img/feature/2.png"
                className="img-fluid"
                alt="Imageteam"
              />
            </div>
            <div className='desc' style={{ marginBottom: '70px' }}>
              <div className='name'>Colombo Caregiver & Health Science School</div>
              <div className='content'>
                Sri Lanka’s premier institution for dedicated caregiver training.
                Only accredited ACA Franchise in Sri Lanka. Sri Lanka's exclusive provider of the National Caregiver Certificate Course (NCCC) USA.
              </div>
            </div>
            <br />
            <div className='icon mb-4'>
              <span onClick={() => window.open("https://www.ccsedu.lk/", "_blank")}>
                View Site
              </span>
            </div>
          </CustomSlide>

          <CustomSlide className='itm' index={3}>
            <div className='bg'>
              <img
                src="./img/feature/4.png"
                className="img-fluid"
                alt="Imageteam"
              />
            </div>
            <div className='desc' style={{ marginBottom: '120px' }}>
              <div className='name'>
                JobDaddy.LK
              </div>
              <div className='content'>
                Empowering job seekers to find their dream careers, one opportunity at a time.
              </div>
            </div>
            <div className='icon'>
              <span onClick={() => window.open("https://jobdaddy.lk/", "_blank")}>
                View Site
              </span>
            </div>
          </CustomSlide>

          <CustomSlide className='itm' index={4}>
            <div className='bg'>
              <img
                src="./img/feature/3.png"
                className="img-fluid"
                alt="Docmart Pharmaceuticals"
              />
            </div>
            <div className='desc' style={{ marginBottom: '50px' }}>
              <div className='name'>Docmart Pharmaceuticals (Pvt) Ltd</div>
              <div className='content'>
                Your trusted partner for high-quality pharmaceuticals. Providing reliable healthcare solutions to communities, one product at a time.
              </div>
            </div>
            <div className='icon'>
              <span onClick={() => window.open("https://docmart.lk/", "_blank")}>
                View Site
              </span>
            </div>
          </CustomSlide>


        </Slider>
      </div>
    );
  }
}
